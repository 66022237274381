// Reset css
html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
del,
em,
img,
small,
strong,
b,
u,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
button,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: inherit;
  font-weight: inherit;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}

a img {
  border: none;
}

article,
aside,
details,
figure,
footer,
header,
menu,
nav,
section,
summary {
  display: block;
}

button,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  scroll-behavior: smooth;
  background-color: #000;
}

button {
  background-color: transparent;
  border: none;
  color: #fff;
}

body {
  overflow-x: hidden;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
}

.text-gradient {
  background: linear-gradient(74deg, #a541ff 0%, #79d0f1 70%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  line-height: 20px;
}

a {
  transition: color 0.3s linear;
}

// Common
.container {
  width: 70%;
  margin: 0 auto;
  @media screen and (max-width: 1400px) {
    width: 80%;
  }
  @media screen and (max-width: 700px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 95% !important;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.min-row {
  width: 95%;
  margin: 0 auto;
}

.row-border {
  border-bottom: 2px solid #686cdc;
}

.w-full {
  width: 100%;
}

.btn {
  padding: 14px 25px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.3s linear;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 1250px) {
    padding: 10px;
  }
  @media screen and (max-width: 550px) {
    font-size: 13px;
  }
  @media screen and (max-width: 450px) {
    padding: 8px;
    font-size: 12px;
  }
  &-small {
    padding: 8px 15px !important;
    @media screen and (max-width: 1250px) {
      padding: 8px 10px !important;
    }
  }
  &-medium {
    padding: 12px 30px !important;
    @media screen and (max-width: 1250px) {
      padding: 10px 15px !important;
    }
  }
}

.center {
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.btn-primary {
  background-color: #686cdc;
}

.btn-secondary {
  background-color: #fc7100;
}

.btn-disabled {
  background-color: gray;
}

.sub-title {
  font-size: 27px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    font-size: 22px;
  }
}

.text-orange {
  font-weight: bold;
  background: rgb(252, 154, 0);
  background: linear-gradient(
    266deg,
    rgba(252, 154, 0, 1) 0%,
    rgba(250, 178, 13, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-orange-dark {
  color: #fca809;
}

.text-black {
  color: #000;
}

.text-link {
  border-bottom: 1px solid #fca809;
}

.text-bold {
  font-weight: bold;
}

.app {
  background-color: #03040a;
  color: #fff;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.modal-custom-close {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #f2f2f2;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  svg {
    width: 20px;
    height: 20px;
    fill: #656969;
  }
}

input {
  outline: none;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.modal-content {
  position: relative;
  z-index: 999999;
  width: 60%;
  max-width: 500px;
  min-height: 200px;
  background-color: white;
  color: #000;
  border-radius: 15px;
  @media screen and (max-width: 800px) {
    width: 90%;
  }
}
